<template>
<CModalExtended
  :title="getTitulo"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-content-buque"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard class="card-simple">
          <CCardBody>
            <CRow class="justify-content-center mt-1 mb-4">

              <CCol sm="4">
                <div class="preview">    
                  <picture-input
                    v-if="modalActive"
                    ref="imageInput"
                    :imgSrc="image"
                    width="200"
                    height="200"
                    accept="image/jpeg,image/png"
                    size="10"
                    :custom-strings="imgInputTexts"
                    @change="handleFileUpload"
                  >
                  </picture-input>
                </div>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.tipoBuque.TpVesselName.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.tipoBuque.TpVesselDs)"
                      :is-valid="hasError($v.tipoBuque.TpVesselName)"
                      :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                      size="sm"
                      :label="$t('label.name')"
                      :placeholder="$t('label.vesselTypeName')"
                      addLabelClasses="required text-right"
                      maxlength="50"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CTextarea
                      v-model.trim="$v.tipoBuque.TpVesselDs.$model"
                      :invalid-feedback="errorMessage($v.tipoBuque.TpVesselDs)"
                      :is-valid="hasError($v.tipoBuque.TpVesselDs)"
                      :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                      size="sm"
                      :label="$t('label.description')"
                      :placeholder="$t('label.vesselTypeDescription')"
                      addLabelClasses="required text-right"
                      maxlength="500"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CSelect
                      :value.sync="tipoBuque.Status"
                      :is-valid="statusSelectColor"
                      :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                      size="sm"
                      :label="$t('label.status')"
                      :placeholder="$t('label.status')"
                      :options="statusOptions"
                      addLabelClasses="text-right"
                      class="mt-1"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer>
    <!-- :disabled="isSubmitValid" -->
    <CButton
      color="add"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="statusConfirmation(tipoBuqueData.FgActTpVessel, tipoBuque.Status, submitFile, tipoBuqueData.TpVesselName)"
    >
      <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
    <!--
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </div>
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{$t('button.accept')}}</span>
      </div>-->
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import TipoBuqueValidations from '@/_validations/tipo-buque/TipoBuqueFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import TipoBuqueMixin from '@/_mixins/tipo-buque';
import ModalMixin from '@/_mixins/modal';

import { GenerateImgPlaceholder } from '@/_helpers/funciones';
import PictureInput from '@/components/picture-input';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    tipoBuque: {
      TpVesselId: '',
      TpVesselName: '',
      TpVesselDs: '',
      Route: '',
      Status: 0
    },
    titulo: '',
    image: ''
  }
}

//Methods
function submitFile() {
  if(this.tipoBuqueData.Route == this.tipoBuque.Route)
    this.submit();
  else{
    try {
      this.isSubmit = true;
      this.$v.tipoBuque.$touch();

      if (this.$v.tipoBuque.$error) {
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }
  
      this.$http.file('TpVessel-update', this.tipoBuque.Route, this.tipoBuqueData.Route)
      .then((response) => {
        this.tipoBuque.Route = response.data.data.files[0].path;
        this.submit();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.isSubmit = false;
      });
    } catch (e) {
      this.notifyError({ text: e });
    }
  }
}
function submit() {
  try {
    this.isSubmit = true;
    this.$v.tipoBuque.$touch();

    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let TpVesselJson = {
      TpVesselId: this.tipoBuque.TpVesselId,
      TpVesselName: this.tipoBuque.TpVesselName,
      TpVesselDs: this.tipoBuque.TpVesselDs,
      Route: this.tipoBuque.Route,
      Status: this.tipoBuque.Status
    };

    this.$http.put('TpVessel-update', TpVesselJson, { root: 'TpVesselJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  else
    this.$v.tipoBuque.$touch();
    
  this.modalActive = newVal;
}
function setTpBuqueData(newTpBuque) {
  this.tipoBuque.TpVesselId = newTpBuque.TpVesselId;
  this.tipoBuque.TpVesselName = newTpBuque.TpVesselName;
  this.tipoBuque.TpVesselDs = newTpBuque.TpVesselDs;
  this.tipoBuque.Route = newTpBuque.Route;
  this.tipoBuque.Status = newTpBuque.FgActTpVessel?1:0;
  this.titulo = this.$t('label.edit')+this.$t('label.vesselType')+`: ${newTpBuque.TpVesselName}`;

  this.image = newTpBuque.Route?`${this.$store.getters["connection/getBase"]}${newTpBuque.Route.replace('public/', '', null, 'i')}`:GenerateImgPlaceholder()
}
function resetForm() {
  this.tipoBuque.TpVesselId = '';
  this.tipoBuque.TpVesselName = '';
  this.tipoBuque.TpVesselDs = '';
  this.tipoBuque.Route = '';
  this.image = '';
  this.$v.$reset();
}
function handleFileUpload(img) {
  this.tipoBuque.Route = this.$refs.imageInput.file;
  this.image = '';
}

//Computeds:
function getTitulo() {
  return this.titulo;
}
function statusSelectColor(){
  return this.tipoBuque.Status === 1;
}

export default {
  name: 'edit-tipo-buque-modal',
  mixins: [
    TipoBuqueMixin,
    ModalMixin
  ],
  props: {
    modal: Boolean,
    tipoBuqueData: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    PictureInput
  },
  data,
  validations: TipoBuqueValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    handleFileUpload,
    setTpBuqueData,
    submitFile
  },
  computed: {
    getTitulo,
    statusSelectColor
  },
  watch: {
    tipoBuqueData: function(newTpBuque) {
      this.setTpBuqueData(newTpBuque);
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  height: 15em;
  color: white;
  text-shadow:
      3px 3px 0 #000,
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
      1px 1px 0 #000;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.modal-content-buque {
  .modal-content {
    width: 70% !important;
    margin: auto !important;
  }
}
</style>